import { ReactNode } from "react"

const Card = ({ children }: { children?: ReactNode }) => (
  <div className="mx-auto md:max-w-[824px] bg-transparent">
    <div className="px-6 pt-8 pb-10 bg-white md:px-8 md:py-14">{children}</div>
  </div>
)

Card.Container = ({
  children,
  className: extraClassNames = ""
}: {
  children?: ReactNode
  className: string
}) => (
  <div className={"mx-auto md:max-w-[824px] bg-transparent " + extraClassNames}>
    {children}
  </div>
)

Card.PreElection = ({
  children,
  className: extraClassNames = ""
}: {
  children?: ReactNode
  className?: string
}) => (
  <div className={`mx-auto md:max-w-[824px] bg-transparent ${extraClassNames}`}>
    <div className="md:pb-10 md:py-8 bg-white relative">{children}</div>
  </div>
)

Card.ElectionPagePadding = ({ children }: { children?: ReactNode }) => (
  <div className="px-6 py-10 bg-white md:px-8 md:py-6">{children}</div>
)

Card.RacesToFollow = ({ children }: { children?: ReactNode }) => (
  <div className="px-6 pt-8 pb-20 bg-white md:px-8 md:py-6">{children}</div>
)

export default Card
