import Link from "@/src/common/components/Link"
import classNames from "classnames"
import { ReactNode } from "react"

import Spinner from "../icon/Spinner"

const BUTTON_TYPES = {
  cta: "bg-red-600 hover:bg-stears_red text-white focus-visible:ring-stears_red focus-visible:ring-2 focus-visible:ring-offset-2 text-btn_14 mobile:h-[50px] h-12 dark:h-max-content dark:bg-gray-600 dark:lg:text-xs dark:mobile:text-p_10 dark:p-4",
  primary:
    "bg-stears_black hover:bg-gray-600 text-white focus-visible:ring-stears_black focus-visible:ring-2 focus-visible:ring-offset-2 text-btn_14 mobile:h-[50px] h-12 dark:h-max-content dark:bg-[#1A1D21] dark:lg:text-xs dark:mobile:text-p_10 dark:px-5 dark:py-2.5 dark:border-gray-600 dark:border-w_0_5 dark:border-solid",
  secondary:
    "bg-inherit border-2  border-gray-600 hover:border-stears_black text-stears_black focus-visible:ring-gray-400 focus-visible:ring-2 focus-visible:ring-offset-2 text-btn_14 mobile:h-[50px] h-12 ",
  tertiary:
    "bg-stears_white hover:stears_white text-stears_black text-btn_14 mobile:h-[50px] h-12 border border-border-20",
  danger: "bg-[#F05042] text-stears_white text-xs mobile:h-[50px] h-12"
}

type Shared = {
  type: keyof typeof BUTTON_TYPES
  id?: string
  /**
   * @deprecated Use `roundness` property instead
   */
  pill?: boolean
  stretch?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  children: ReactNode
  loading?: boolean
  disabled?: boolean
  fontbold?: boolean
  onClick?: () => void
  noWrap?: boolean
  roundness?: "rounded" | "pill" | "edge"
  extraClassNames?: string
}

type LinkProps = Shared & {
  href: string
  target: string
  download: boolean
}

type BaseButtonProps = Shared & {
  htmlType?: "button" | "submit"
}

export type ButtonProps = LinkProps | BaseButtonProps

const parseButtonProps = (props: LinkProps | BaseButtonProps) => {
  const {
    type,
    pill: rounded,
    stretch,
    loading,
    rightIcon,
    onClick,
    noWrap,
    roundness,
    extraClassNames
  } = props

  let roundnessClassNames = rounded
    ? "rounded-full text-btn_12 h-9 mobile:h-9"
    : "rounded"

  /**
   * Overwrite pill prop usage if roundness prop is set
   *
   * PS: This implementation is done for backward compatibility
   */
  if (roundness) {
    roundnessClassNames = {
      rounded: "rounded",
      edge: "rounded-0",
      pill: "rounded-full text-btn_12 h-9 mobile:h-9"
    }[roundness]
  }

  const stretchClass = stretch ? " w-full" : "w-fit"
  const iconLeftMargin = stretch ? "mr-4" : "mr-2"
  const iconRightMargin = stretch ? "ml-8" : "ml-2"

  const right = loading ? <Spinner /> : rightIcon
  const extraClassName = extraClassNames ? extraClassNames : ""

  return {
    button: classNames(
      BUTTON_TYPES[type],
      stretchClass,
      roundnessClassNames,
      {
        "whitespace-nowrap": noWrap
      },
      "font-jakarta px-6 transition-colors delay-250 ease-out outline-none",
      "inline-flex items-center align-middle justify-center cursor-pointer",
      "disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-400",
      "flex",
      extraClassName
    ),
    leftIconClass: `${iconLeftMargin} min-w-[12px]`,
    rightIconClass: iconRightMargin,
    right,
    onClick: loading ? undefined : onClick
  }
}

const BaseButton = (props: BaseButtonProps) => {
  const { id, leftIcon, children, htmlType, disabled } = props
  const { button, leftIconClass, right, rightIconClass, onClick } =
    parseButtonProps(props)

  return (
    <button
      id={id}
      className={button}
      onClick={onClick}
      type={htmlType}
      disabled={disabled}
    >
      {leftIcon && <span className={leftIconClass}> {leftIcon}</span>}
      {children}
      {right && <span className={rightIconClass}> {right}</span>}
    </button>
  )
}

const LinkButton = (props: LinkProps) => {
  const { leftIcon, href, children, target } = props
  const { button, leftIconClass, right, rightIconClass, onClick } =
    parseButtonProps(props)
  const computedTarget = href.startsWith("http") ? "_blank" : ""
  return (
    <Link legacyBehavior href={href}>
      <a
        className={button}
        target={target || computedTarget}
        onClick={onClick}
        rel="noreferrer"
      >
        {leftIcon && <span className={leftIconClass}> {leftIcon}</span>}
        {children}
        {right && <span className={rightIconClass}> {right}</span>}
      </a>
    </Link>
  )
}

const Button = (props: ButtonProps) => {
  if ("href" in props) {
    return <LinkButton {...props} />
  }
  return <BaseButton {...props} />
}

export default Button
