import { URLFactory } from "@/src/sb/business-layer/factories/URLFactory"
import {
  AnonymousUser,
  LicenceType,
  LoggedInUser,
  MemberProfile,
  SidebarMenuItemV2
} from "@/src/sb/business-layer/types"
import { Intelligence } from "@/src/sb/components/Intelligence"
import { NumberedPaginationProps } from "@/src/sb/components/Pagination"
import {
  DATA_PAGE_AUTHORIZED_LICENCE_TIERS,
  MATCH_TO_IGNORE_INTELLIGENCE_SOLUTIONS_BANNER_PLATFORM,
  MATCH_TO_SHOW_INTELLIGENCE_SOLUTIONS_BANNER
} from "@/src/sb/constants"
import { V2_FEATURE_TOGGLES } from "@/src/sb/constants/configs"
import { WEBFLOW } from "@/src/sb/constants/featureFlags"
import _ from "lodash"

import { Article } from "../models"

export const isEmpty = (obj: object) =>
  !Object.values(obj).map(Boolean).includes(true)

export const getImageSrc = (article: Article) => {
  if (!article.image) return {}

  if (typeof article.image === "string") {
    return {
      src: article.image,
      srcSet: article.image_src_set || "",
      alt_text: ""
    }
  }
  return {
    src: article.image.source_url,
    srcSet: article.image_src_set || "",
    alt_text: article.image.alt_text
  }
}

const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ")
  const last = (lastName || " ")[0].toUpperCase()
  return `${firstName} ${last}.`
}
export const getAuthor = (author: Article["author"]) => {
  if (typeof author === "string") {
    return {
      name: getInitials(author),
      id: -1
    }
  }

  author.name = getInitials(author.name ? author.name : "")
  return author
}

export const filterObject = (
  obj: Record<string, any>,
  desiredFields: string[]
): Record<string, any> | null => {
  const filteredObj = obj
    ? Object.keys(obj)
        .filter((key) => desiredFields.includes(key))
        .reduce((newObj, key) => {
          newObj[key] = obj?.[key]
          return newObj
        }, {} as any)
    : {}

  return Object.keys(filteredObj).length > 0 ? filteredObj : null
}

/**
 * Return true if article is free
 * @param report
 */
export const isReportFree = (report: { type?: string }) => {
  return !report.type || report.type === "free"
}

/**
 * Return true if article is free
 * @param user
 */
export const userHasActiveSubscription = (
  user: Pick<LoggedInUser, "userType"> & Partial<LoggedInUser | AnonymousUser>
) => {
  return user?.userType === "subscriber"
}

/**
 * Return true if webflow is enabled and the current path is a match
 * @param path
 */
export const showIntelligenceBannerOnPath = (
  path: string,
  platformisation?: boolean
): boolean => {
  return Boolean(
    V2_FEATURE_TOGGLES.has(WEBFLOW) &&
      MATCH_TO_SHOW_INTELLIGENCE_SOLUTIONS_BANNER.concat(
        platformisation
          ? []
          : MATCH_TO_IGNORE_INTELLIGENCE_SOLUTIONS_BANNER_PLATFORM
      ).some((match) => match.test(path))
  )
}

const getIntelligenceDestination = (
  typeIsReport: boolean,
  item: any
): string => {
  const datasetIsCountryProfile =
    !typeIsReport && item.countryRiskSlug && item.tabSlug
  const computedSlug = datasetIsCountryProfile
    ? item.countryRiskSlug
    : item.slug

  const destinationSearchParams = new URLSearchParams()
  if (item.tabSlug) destinationSearchParams.set("tab", item.tabSlug)
  if (item.countryRiskSlug) destinationSearchParams.set("dataset", item.slug)

  return typeIsReport
    ? URLFactory.report(item.slug, isReportFree(item))
    : `${
        item.bucketSlug === "datasets"
          ? `/${item.bucketSlug}/${item.slug}/`
          : item.groupSlug
          ? item.bucketSlug
            ? `/${item.bucketSlug}/${item.groupSlug}/${computedSlug}/`
            : URLFactory.dataPage(`${item.groupSlug}/${computedSlug}/`)
          : `/${item.slug}/`
      }${
        Array.from(destinationSearchParams.entries()).length
          ? `?${destinationSearchParams.toString()}`
          : ""
      }`
}

export const transformIntelligenceResponse = (
  intelligenceResponse: any,
  usesSearch: boolean,
  type: "datasets" | "reports" = "reports"
): { records: Array<Intelligence>; pagination?: NumberedPaginationProps } => {
  if (!intelligenceResponse) return { records: [] }

  const intelligenceTypeIsReport = type === "reports"

  if (usesSearch) {
    const _intelligence =
      intelligenceResponse.reports || intelligenceResponse.datasets

    return {
      records:
        _intelligence?.hits.map(
          (item: any): Intelligence => ({
            name: item.name || item.title,
            destination: getIntelligenceDestination(
              intelligenceTypeIsReport,
              item
            ),
            pubDate: intelligenceTypeIsReport ? item.date : null,
            tabSlug: intelligenceTypeIsReport ? null : item.tabSlug,
            countryRiskSlug: intelligenceTypeIsReport
              ? null
              : item.countryRiskSlug,
            countryRiskName: intelligenceTypeIsReport
              ? null
              : item.countryRiskName,
            groupSlug: intelligenceTypeIsReport ? null : item.groupSlug
          })
        ) || [],
      pagination: {
        limit: _intelligence.hitsPerPage as number,
        totalNumberOfRecords: _intelligence.nbHits
      }
    }
  }

  return {
    records: intelligenceResponse.items.map(
      (item: any): Intelligence => ({
        name: item.name || item.title,
        destination: getIntelligenceDestination(intelligenceTypeIsReport, item),
        pubDate: intelligenceTypeIsReport ? item.date : null,
        tabSlug: intelligenceTypeIsReport ? null : item.tabSlug,
        countryRiskSlug: intelligenceTypeIsReport ? null : item.countryRiskSlug,
        countryRiskName: intelligenceTypeIsReport ? null : item.countryRiskName,
        groupSlug: intelligenceTypeIsReport ? null : item.groupSlug
      })
    ),
    pagination: {
      limit: intelligenceResponse.limit,
      totalNumberOfRecords: intelligenceResponse.count
    }
  }
}

export const userHasValidDataLicence = (
  memberProfile: MemberProfile | undefined
) =>
  !!memberProfile &&
  memberProfile.roles.includes("premium") &&
  DATA_PAGE_AUTHORIZED_LICENCE_TIERS.includes(
    memberProfile.corporateSubscriberLicence?.toLowerCase() as LicenceType
  )

export function flattenSidebarItems(
  items: SidebarMenuItemV2[]
): SidebarMenuItemV2[] {
  return _.flatMapDeep(items, (item) => {
    const { children, ...rest } = item
    return children ? [rest, ...flattenSidebarItems(children)] : rest
  })
}

export function filterSidebarItems(
  items: SidebarMenuItemV2[],
  searchTerm: string
): SidebarMenuItemV2[] {
  return items
    .map((item): SidebarMenuItemV2 | null => {
      // Recursively filter children
      const matchingChildren = item.children
        ? filterSidebarItems(item.children, searchTerm)
        : []

      // Check if the current item matches the search term
      const isMatch = item.name.toLowerCase().includes(searchTerm.toLowerCase())

      // Include the item if it matches or has matching children
      if (isMatch || matchingChildren.length > 0) {
        return {
          ...item,
          children: matchingChildren
        }
      }

      // Exclude the item if neither it nor its children match
      return null
    })
    .filter((item) => !!item) as SidebarMenuItemV2[] // Type guard to remove null values
}

export function hasValidValue(obj: any): boolean {
  if (Array.isArray(obj)) {
    // Check if at least one item in the array is valid
    return obj.some((item) => hasValidValue(item))
  } else if (typeof obj === "object" && obj !== null) {
    // Check if at least one property in the object is valid
    return Object.values(obj).some((value) => hasValidValue(value))
  }

  // Non-object, non-array values are valid if they are not null or undefined
  return obj !== null && obj !== undefined
}

export function getFlatSidebarMenuItemsWithDestination(
  items: SidebarMenuItemV2[]
): SidebarMenuItemV2[] {
  const result: SidebarMenuItemV2[] = []

  function visitNode(item: SidebarMenuItemV2) {
    // Check if the destination starts with "/open-data"
    if (item.destination && item.destination.startsWith("/open-data")) {
      result.push(item)
    }

    // If the item has children, recursively visit each child
    if (item.children) {
      item.children.forEach(visitNode)
    }
  }

  // Start the recursive visiting process for each item in the root list
  items.forEach(visitNode)

  return result
}
