import { OperatingSystems } from "@/src/common/types"
import { useAnalytics } from "@/src/sb/components/Analytics"
import QRCodeModal from "@/src/sb/components/DownloadMobileNudge/QRCodeModal"
import Image from "next/legacy/image"
import React, { useState } from "react"

import { MOBILE_APP_DOWNLOAD_LINK } from "../../constants/configs"
import Button from "../Button"

export const AsideDownloadMobile = () => {
  const analytics = useAnalytics()

  const [showQR, setShowQR] = useState(false)

  const toggleQRCode = (os?: OperatingSystems) => {
    if (!showQR && os) {
      analytics?.clickDownloadMobileApp(os)
    }
    setShowQR(!showQR)
  }
  return (
    <>
      {showQR && <QRCodeModal onClose={() => setShowQR(false)} />}

      <div className="bg-[#ECF7ED] md:bg-white p-6">
        <div className="mb-4">
          <h5 className="mb-2 text-base md:text-lg font-medium">
            Download our mobile app
          </h5>
          <p className="text-gray-600 text-sm md:text-base">
            Get elections insights on the go!
          </p>
        </div>

        <div className="flex items-center">
          <button
            type="button"
            onClick={() => toggleQRCode("android")}
            className="hidden md:inline-block relative h-12 w-[164px] mr-3"
            aria-label="Download Android app"
          >
            <Image
              src="/assets/icons/playstore-download.png"
              layout="fill"
              objectFit="contain"
              alt="download on android"
            />
          </button>

          <button
            type="button"
            onClick={() => toggleQRCode("iOS")}
            className="hidden md:inline-block relative h-12 w-[164px]"
            aria-label="Download iOS app"
          >
            <Image
              src="/assets/icons/ios-download.png"
              layout="fill"
              objectFit="contain"
              alt="download on iOS"
            />
          </button>

          {/*Show on mobile*/}
          <div className="md:hidden grow">
            <Button
              type="primary"
              stretch={true}
              roundness="edge"
              href={MOBILE_APP_DOWNLOAD_LINK}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AsideDownloadMobile
