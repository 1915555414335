import Image from "next/legacy/image"
import React from "react"

type LogoValue = {
  src: string
  alt: string
  dimensions: string
  objectFit?: "cover" | "fill"
}
const SVG_MAPPER = {
  red: {
    src: "/assets/icons/sb_logo_red.svg",
    alt: "Stears Logo red",
    dimensions: "w-32 h-8 md:w-36 md:h-9"
  },
  redLarge: {
    src: "/assets/icons/stears-colored.svg",
    alt: "Stears Logo red",
    dimensions: "w-32 h-8 md:w-36 md:h-9",
    objectFit: "cover"
  },
  black: {
    src: "/assets/icons/sb_logo_black.svg",
    alt: "Stears Logo black",
    dimensions: "w-32 h-8 md:w-36 md:h-9",
    objectFit: "cover"
  },
  white: {
    src: "/assets/icons/sb_logo_white2.svg",
    alt: "Stears Logo white",
    dimensions: "w-32 h-8 tablet:h-12 ",
    objectFit: "cover"
  }
}

export type LogoProps = {
  type?: keyof typeof SVG_MAPPER
  className?: string
}

const Logo = (props: LogoProps) => {
  const { type = "red", className: extraClassNames = "" } = props
  const { src, alt, dimensions, objectFit } = SVG_MAPPER[type] as LogoValue

  return (
    <div className={`relative ${extraClassNames} ${dimensions}`}>
      <Image src={src} alt={alt} layout="fill" objectFit={objectFit} />
    </div>
  )
}

export default Logo
