import alarm from "@/public/assets/icons/elections/alarm.svg"
import arrow from "@/public/assets/icons/elections/arrow.svg"
import chevron from "@/public/assets/icons/elections/chevron.svg"
import minus from "@/public/assets/icons/elections/minus.svg"
import plus from "@/public/assets/icons/elections/plus.svg"
import star from "@/public/assets/icons/elections/star.svg"
import tick2 from "@/public/assets/icons/elections/tick2.svg"
import tick from "@/public/assets/icons/elections/tick.svg"
import whiteArrow from "@/public/assets/icons/elections/white-arrow.svg"
import x2 from "@/public/assets/icons/elections/x2.svg"
import x3 from "@/public/assets/icons/elections/x3.svg"
import x from "@/public/assets/icons/elections/x.svg"
import Image from "next/legacy/image"

const staticIcon = (icon: string) => {
  switch (icon) {
    case "white-arrow":
      return whiteArrow
    case "arrow":
      return arrow
    case "chevron":
      return chevron
    case "tick":
      return tick
    case "tick2":
      return tick2
    case "plus":
      return plus
    case "minus":
      return minus
    case "star":
      return star
    case "alarm":
      return alarm
    case "x":
      return x
    case "x2":
      return x2
    case "x3":
      return x3
  }
}

type Size = "sm" | "xs" | "xxs" | "xs3"
export type IconProps = {
  icon: string
  size: Size
  ext?: "png" | "svg"
  className?: string
  imageClassName?: string
}

const SIZE: { [key in Size]: [string, number] } = {
  xs3: ["h-2 w-2", 8],
  xxs: ["h-[10px] w-[10px]", 10],
  xs: ["h-4 w-4", 16],
  sm: ["h-6 w-6", 24]
}

const Icon = ({
  icon,
  size,
  ext = "svg",
  className: extraClassNames = "",
  imageClassName: extraImageClassNames = ""
}: IconProps) => {
  const [className, dimension] = SIZE[size]
  return (
    <div className={`${className} ${extraClassNames}`}>
      <Image
        src={staticIcon(icon) || `/assets/icons/elections/${icon}.${ext}`}
        alt={icon}
        width={dimension}
        height={dimension}
        layout="responsive"
        className={extraImageClassNames}
      />
    </div>
  )
}

Icon.Chevron = ({
  open = false,
  color = "black",
  ...props
}: Pick<IconProps, "size" | "className" | "imageClassName"> & {
  open?: boolean
  color?: "white" | "black"
}) => (
  <Icon
    {...props}
    icon={color === "white" ? "white-arrow" : "arrow"}
    imageClassName={
      "transition-transform " + (open ? "transform rotate-180" : "")
    }
  />
)

Icon.Chevron2 = ({
  open = false,
  ...props
}: Pick<IconProps, "size" | "className" | "imageClassName"> & {
  open?: boolean
  color?: "white" | "black"
}) => (
  <Icon
    {...props}
    icon="chevron"
    imageClassName={
      "transition-transform " + (open ? "" : "transform rotate-180")
    }
  />
)

export default Icon
