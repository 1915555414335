import { ComponentProps, ReactNode } from "react"

type HTMLTags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p"

type TypographyOption = [string, HTMLTags]
const TYPOGRAPHY_OPTIONS = {
  p32: ["text-p_32", "p"],
  p28: ["text-p_28", "p"],
  p20: ["text-p_20", "p"],
  p18: ["text-p_18b", "p"],
  p18x: ["text-p_18_18", "p"],
  p16: ["text-p_16", "p"],
  p15: ["text-p_15", "p"],
  p15x: ["text-p_15_15", "p"],
  p14: ["text-p_14", "p"],
  p12: ["text-p_12", "p"],
  p12x: ["text-p_12_12", "p"]
  // p1515: ["text-p_15_15", "p"]
  // p_16: ["text-p_16", "p"],
  // p_14: ["text-p_14", "p"],
  // p_10: ["text-p_10", "p"],
  // h1: ["text-h1", "h1"],
  // h2: ["text-h2", "h2"],
  // h3: ["text-h3", "h3"],
  // h4: ["text-h4", "h4"],
  // h5: ["text-h5", "h5"],
  // h6: ["text-h6", "h6"]
}

const FONT_WEIGHT_CLASS = {
  normal: "font-light",
  medium: "font-medium",
  bold: "font-bold"
}

type TypographyProps = {
  children: ReactNode
  type: keyof typeof TYPOGRAPHY_OPTIONS
  weight?: keyof typeof FONT_WEIGHT_CLASS
  className?: string
} & ComponentProps<"p"> // for direct access when setting dynamic styles

const textClassNames = ({
  type,
  weight = "normal",
  className: extraClassNames = ""
}: Pick<TypographyProps, "weight" | "type" | "className">) => {
  const fontWeightClass = FONT_WEIGHT_CLASS[weight]
  const [defaultClassNames] = TYPOGRAPHY_OPTIONS[type] as TypographyOption
  return `font-jakarta ${defaultClassNames} ${fontWeightClass} ${extraClassNames}`
}

const Typography = (props: TypographyProps) => {
  const { children, type, style } = props
  const [, Component] = TYPOGRAPHY_OPTIONS[type] as TypographyOption

  return (
    <Component
      className={`font-jakarta ${textClassNames(props)}`}
      style={style}
    >
      {children}
    </Component>
  )
}

Typography.className = textClassNames
export default Typography
