import { mergeSearchParams } from "@/src/common/utils"
import { useRouter } from "next/router"
import { UrlObject } from "url"

type Url = UrlObject | string

// Gotten from NextRouter internal types
interface ITransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
  unstable_skipClientCache?: boolean
}

interface IOptions {
  /**
   * remove the following queries from being forwarded
   */
  except?: string[]
  /**
   * Set this to true if you want to overwrite queries
   *
   * Default to false
   */
  overwriteQueries?: boolean
}

const useStearsRouter = () => {
  const router = useRouter()

  const pushWithQueries = (
    url: Url,
    stearsRouterOptions?: IOptions,
    nextRouterOptions?: ITransitionOptions
  ) => {
    if (typeof url === "string") {
      return router.push(
        `${url.split("?", 1)[0]}?${mergeSearchParams(
          router.query,
          url
        ).toString()}`,
        undefined,
        nextRouterOptions
      )
    }

    const query = url.query

    return router.push(
      {
        ...url,
        query: Object.fromEntries(mergeSearchParams(router.query, query || {}))
      },
      undefined,
      nextRouterOptions
    )
  }

  return {
    ...router,
    pushWithQueries
  }
}

export default useStearsRouter
