/* eslint react/no-unknown-property: 0 */ // --> OFF
const SpinnerIcon = () => {
  return (
    <svg
      className="animate-spin"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99935 0.333344V2.00001C9.76077 2.00001 11.9993 4.23859 11.9993 7.00001C11.9993 9.76143 9.76077 12 6.99935 12C4.23793 12 1.99935 9.76143 1.99935 7.00001C1.99935 6.13361 2.21928 5.30106 2.63247 4.56263L1.17801 3.74879C0.626555 4.73432 0.332684 5.8468 0.332684 7.00001C0.332684 10.6819 3.31745 13.6667 6.99935 13.6667C10.6812 13.6667 13.666 10.6819 13.666 7.00001C13.666 3.31811 10.6812 0.333344 6.99935 0.333344Z"
        fill="currentColor"
      />
    </svg>
  )
}

type SpinnerProps = {
  children?: string
}
const Spinner = (props: SpinnerProps) => {
  const { children } = props

  if (!children) return <SpinnerIcon />
  return (
    <div className="flex items-center justify-center h-96">
      <p className="mr-2">{props.children}</p>
      <SpinnerIcon />
    </div>
  )
}

export default Spinner
