import Link from "@/src/common/components/Link"
import useTryLocation from "@/src/common/hooks/useTryLocation"
import useVisibilityChange from "@/src/common/hooks/useVisibilityChange"
import { MOBILE_APP_SCHEME_MY_ELECTIONS_PREFERENCE } from "@/src/sb/constants/configs"
import classNames from "classnames"
import React from "react"

import { UPCOMING_ELECTIONS_YEAR } from "../../business-layer/config"
import { URLFactory } from "../../business-layer/factories/URLFactory"
import Icon from "../Icon"
import Logo from "../Logo"
import Template from "../Template"
import Typography from "../Typography"

type HeaderProps = {
  className?: string
  onClickFollowRaces?: () => void
}

const Header = ({
  className: extraClassNames = "",
  onClickFollowRaces
}: HeaderProps) => {
  const { tabIsVisible } = useVisibilityChange()
  const onClickSelectElections = useTryLocation({
    location: MOBILE_APP_SCHEME_MY_ELECTIONS_PREFERENCE,
    fallback: URLFactory.myElections
  })

  const handleClickFollowRace = () => {
    // context: next line mainly logs analytics
    onClickFollowRaces?.()
    onClickSelectElections()
  }

  return (
    <header
      className={`${extraClassNames} px-6 md:px-16 bg-white z-[2000] border-b border-gray-200`}
    >
      <Template.Container>
        <section className="py-5 md:py-4 flex flex-row justify-between">
          <Link
            legacyBehavior
            href={URLFactory.governor(UPCOMING_ELECTIONS_YEAR)}
          >
            <a>
              <Logo color="black" size="sm" className="md:hidden" />
              <Logo color="black" size="md" className="-md:hidden" />
            </a>
          </Link>
          <div className="p-3 md:p-4 bg-black -md:rounded-full">
            <button
              className="flex flex-row justify-between"
              onClick={handleClickFollowRace}
            >
              <div className={classNames({ "animate-wiggle": tabIsVisible })}>
                <Icon icon="alarm" size="xs" />
              </div>
              <Typography
                type="p12x"
                className="md:hidden text-white ml-2 my-auto"
              >
                Set up result alerts
              </Typography>
              <Typography
                type="p15x"
                className="-md:hidden text-white ml-2 my-auto"
              >
                Set up result alerts
              </Typography>
            </button>
          </div>
        </section>
      </Template.Container>
    </header>
  )
}

export default Header
