import exploreResultsAd from "@/public/assets/img/explore-result-ad-v2.png"
import Image from "next/legacy/image"

import Typography from "../Typography"

const ExplorePastResults = ({
  showTitle = true,
  className: extraClassNames = "",
  image
}: {
  size?: "sm" | "md"
  showTitle?: boolean
  className?: string
  image?: {
    width: number
    height: number
    src: string
  }
}) => {
  const defaultImageProps = {
    width: 368,
    height: 234,
    src: exploreResultsAd.src
  }

  return (
    <div
      className={`px-6 md:px-0 bg-white md:bg-transparent md:pt-4 lg:pt-0 pb-6 md:pb-0 ${extraClassNames}`}
    >
      {showTitle && (
        <>
          <div className="md:px-6 lg:px-0">
            <Typography
              type="p15x"
              weight="medium"
              className="text-gray-800 md:hidden"
            >
              Explore past election results
            </Typography>
            <Typography
              type="p20"
              weight="medium"
              className="text-gray-800 -md:hidden"
            >
              Explore past election results
            </Typography>
          </div>
        </>
      )}
      <div className={"w-full mt-4 mx-auto bg-white"}>
        <Image
          {...(image ? image : defaultImageProps)}
          layout="responsive"
          alt="Past election results coming soon"
        />

        <div className={"pt-4 pb-6 px-6 bg-white"}>
          <Typography
            type="p18x"
            weight="medium"
            className="text-center max-w-[270px] mx-auto"
          >
            Coming soon!
          </Typography>
          <Typography
            type="p15"
            className="text-gray-600 mt-2 text-center max-w-[270px] mx-auto"
          >
            Stay tuned for insightful data, including historical results
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ExplorePastResults
