import Link from "@/src/common/components/Link"
import PingSmall from "@/src/common/components/Ping/PingSmall"
import { URLFactory } from "@/src/elections/business-layer/factories/URLFactory"

const GubernatorialLiveBlogCard = ({
  onClickGubernatorialLiveBlog
}: {
  onClickGubernatorialLiveBlog?: () => void
}) => {
  return (
    <div className="bg-white p-6">
      <header className="flex items-center mb-4 font-bold space-x-3.5 font-jakarta">
        <span className="inline-block px-2.5 p-2 text-sm bg-stears_red text-white rounded-lg">
          LIVE
        </span>
        <span className="inline-block">UPDATES</span>
        <PingSmall />
      </header>

      <h5 className="text-[18px] leading-[18px] font-bold mb-2">
        Follow Election Day coverage on Stears’ live blog
      </h5>
      <p className="mb-5">
        Get the latest updates on races, results and election data
      </p>
      <Link
        legacyBehavior
        href={URLFactory.absolute("gubernatorialElectionLiveBlog")}
      >
        <a
          onClick={onClickGubernatorialLiveBlog}
          target="_blank"
          className={`block mt-5 bg-black text-white flex items-start justify-center w-full p-4 text-[15px] font-bold`}
        >
          Visit live blog
        </a>
      </Link>
    </div>
  )
}

export default GubernatorialLiveBlogCard
