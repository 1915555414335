import { ArticleBlock } from "../../../common/components/ArticleBlock"
import { ElectionArticleInfo } from "../../business-layer/types"
import Typography from "../Typography"

type ArticleFeedProps = {
  onClickAction?: (article: ElectionArticleInfo) => void
  articles: Array<ElectionArticleInfo>
}

const ArticleFeed = ({ articles, onClickAction }: ArticleFeedProps) => (
  <div className="pt-8 md:pt-0 pb-7 bg-white md:bg-transparent">
    <Typography
      type="p15x"
      weight="medium"
      className="px-6 lg:px-0 text-gray-800 md:hidden"
    >
      Explore popular articles and opinions
    </Typography>
    <Typography
      type="p20"
      weight="medium"
      className="px-6 lg:px-0 text-gray-800 -md:hidden"
    >
      Explore popular articles and opinions
    </Typography>
    <ul className="mt-4 px-5 py-1 bg-white">
      {articles.map((article, idx) => (
        <li key={idx} className="first:border-0 border-t border-gray-10">
          <ArticleBlock.Landscape
            legacyStyles={false}
            imageType="rectangle"
            image={{
              src: article.img,
              alt: article.imgAlt,
              srcSet: article.imgSrcSet || undefined
            }}
            title={article.title}
            slug={article.slug}
            authorName={article.author}
            publishedDate={article.date ? new Date(article.date) : undefined}
            onClick={(_) => onClickAction?.(article)}
          />
        </li>
      ))}
    </ul>
  </div>
)

export default ArticleFeed
