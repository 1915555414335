import { useEffect, useState } from "react"

const isClientSide = () => typeof window !== "undefined"

// see this https://stackoverflow.com/questions/63406435/how-to-detect-window-size-in-next-js-ssr-using-react-hook
export function useWindowSize() {
  const [{ width, height }, setWindowSize] = useState({
    width: 0,
    height: 0
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    if (isClientSide()) {
      window.addEventListener("resize", handleResize)
      handleResize()
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [])
  return [width < 768, width, height]
}
