import useVisibilityChange from "@/src/common/hooks/useVisibilityChange"
import { useEffect, useRef } from "react"

const useTryLocation = ({
  location,
  fallback,
  /** wait for waitTime secs before using the fallback location/url */
  waitTime = 3000
}: {
  location: string
  fallback: string
  waitTime?: number
}): (() => void) => {
  const unLoadTimeout = useRef<NodeJS.Timeout | null>(null)
  const { tabIsVisible } = useVisibilityChange()

  useEffect(() => {
    return () => {
      if (unLoadTimeout.current) {
        clearTimeout(unLoadTimeout.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!tabIsVisible && unLoadTimeout.current) {
      clearTimeout(unLoadTimeout.current)
    }
  }, [tabIsVisible])

  return (): void => {
    window.location.href = location
    unLoadTimeout.current = setTimeout(() => {
      // TODO: Improve implementation based on feedback from stakeholders
      window.location.href = fallback
    }, waitTime)
  }
}

export default useTryLocation
