import { LinkProps, default as NextLink } from "next/link"
import { ComponentPropsWithRef, forwardRef } from "react"

export type StearsLinkProps = ComponentPropsWithRef<"a"> & LinkProps

const Link = forwardRef<HTMLAnchorElement, StearsLinkProps>(
  ({ children, ...props }: StearsLinkProps, ref) => {
    return (
      <NextLink {...props} prefetch={props.prefetch ?? false} ref={ref}>
        {children}
      </NextLink>
    )
  }
)

export default Link
