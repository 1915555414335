import blackLogo from "@/public/assets/logos/stears-elections-black-2.png"
import whiteLogo from "@/public/assets/logos/stears-elections-white-2.png"
import Image from "next/legacy/image"
import React from "react"

type ImageSrc = "white" | "black"
type ImageSrcProps = {
  src: any
  alt: string
}
const IMAGE_SRC: { [color in ImageSrc]: ImageSrcProps } = {
  white: {
    src: whiteLogo,
    alt: "Stears Elections"
  },
  black: {
    src: blackLogo,
    alt: "Stears Elections"
  }
}

type ImageSize = "sm" | "md"
const INTRINSIC = {
  width: 300,
  height: 59
}
const IMAGE_SIZE: { [size in ImageSize]: string } = {
  sm: "h-full w-36",
  md: "h-full w-44"
}

export type LogoProps = {
  color: keyof typeof IMAGE_SRC
  size: ImageSize
  className?: string
}

const Logo = ({ color, size, className: extraClassNames = "" }: LogoProps) => {
  const { width, height } = INTRINSIC
  return (
    <div
      className={`flex flex-col justify-center ${IMAGE_SIZE[size]} ${extraClassNames}`}
    >
      <div>
        <Image
          {...IMAGE_SRC[color]}
          alt="Stears Elections"
          width={width}
          height={height}
          layout="responsive"
        ></Image>
      </div>
    </div>
  )
}

export default Logo
