import Footer from "../Footer"
import Header from "../Header"

export type LayoutProps = {
  children: React.ReactNode
  className?: string
  onClickFollowRaces?: () => void
}
const Layout = ({
  onClickFollowRaces,
  children,
  className: extraClassNames = ""
}: LayoutProps) => {
  return (
    <>
      <Header
        className="sticky top-0"
        onClickFollowRaces={onClickFollowRaces}
      />
      <main className={`block bg-gray-100 ${extraClassNames}`}>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
