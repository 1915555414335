import Image from "next/legacy/image"
import React, { useEffect } from "react"

import Typography from "../Typography"

type QRCodeModalProps = {
  onClose: () => void
}

export const QRCodeModal = (props: QRCodeModalProps) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    // Candidate table uses z-index:1010
    <div className="bg-[#000000CC] top-0 left-0 fixed w-[100vw] h-[100vh] flex items-center justify-center z-[1011]">
      <div className="bg-stears_white h-[400px] w-[376px] rounded-2xl py-5 px-4 text-center">
        <div className="close-wrapper flex justify-end ">
          <button
            type="button"
            onClick={props.onClose}
            className=" block  bg-[#EEF1F6] rounded w-7 h-7"
          >
            <Image
              src="/assets/icons/close-modal.svg"
              alt="close modal"
              layout="fixed"
              width={10}
              height={10}
            />
          </button>
        </div>

        <div className="mb-10">
          <Typography type="h5" family="jakarta">
            Scan QR code
          </Typography>
        </div>

        <div className="img-wrapper mb-3 h-[173px] relative">
          <Image
            width={135}
            height={135}
            src="/landing-page/qr-code.png"
            alt="qr code"
            layout="fixed"
          />
        </div>

        <div className="flex justify-center">
          <div className="w-[301px]">
            <Typography type="p_15" family="jakarta" fontWeight="regular">
              Please scan the QR code to download the mobile app from Google
              Play or App Store
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QRCodeModal
