import { BASE_URL } from "@/src/common/config"
import { DEFAULT_ELECTION_YEAR } from "@/src/elections/business-layer/constants"
import { pages } from "@/src/sb/constants/copy"
import { startOfYear, subDays, subMonths } from "date-fns"

import { Race } from "../types"

const appendSlash = (r: string) => (!r ? "" : r.endsWith("/") ? r : r + "/")
const query = (q: string) => (q ? "?" + q : "")
const stateConstituencyLink = (state: string, constituency?: string) =>
  appendSlash(state) +
  query(constituency ? new URLSearchParams({ constituency }).toString() : "")
const stateLGALink = (state: string, lga?: string) =>
  appendSlash(state) + query(lga ? new URLSearchParams({ lga }).toString() : "")

export class URLFactory {
  static myElections = "/elections/my-elections/"
  static gubernatorialElectionLiveBlog =
    "/elections/nigeria-2023-live-governorship-election-results/"
  static socialCardImage = "/assets/img/elections-og-image.jpg"
  static socialCardGovernorImage =
    "/assets/img/gubernatorial-elections-og-image.png"
  static socialCardLiveBlogImage = "/assets/img/live-blog-og-image.png"
  static socialCardGovernorshipLiveBlogImage =
    "/assets/img/live-governorship-blog-og.png"
  static external = {
    signup: "/accounts/signup/"
  }
  static commonPages = pages // TODO: refactor to use same factory
  static election(
    el: keyof typeof URLFactory | Race,
    year = DEFAULT_ELECTION_YEAR,
    ...args: any[]
  ) {
    return typeof URLFactory[el] === "function"
      ? (URLFactory[el] as any)(year, ...args)
      : URLFactory[el]
  }
  static absolute(
    el: keyof typeof URLFactory,
    year = DEFAULT_ELECTION_YEAR,
    ...args: any[]
  ) {
    return BASE_URL + URLFactory.election(el, year, ...args)
  }
  static myElectionsCTA = (
    race: Race,
    year = DEFAULT_ELECTION_YEAR,
    ...args: any[]
  ) =>
    `${
      URLFactory.external.signup
    }?theme=elections&intent=elections&next=${URLFactory.election(
      race as any,
      year,
      ...args
    )}?follow=true`

  static candidate(candidate: string) {
    return "/elections/candidates/" + appendSlash(candidate)
  }

  static governor(year: string, state = "") {
    return `/elections/${year}/governor/${appendSlash(state)}`
  }

  static president(year: string, state = "", lga = "") {
    return `/elections/${year}/president/${stateLGALink(state, lga)}`
  }

  static root(year = DEFAULT_ELECTION_YEAR) {
    return `/elections/${year}/`
  }

  static houseOfReps(year: string, state = "", constituency = "") {
    return `/elections/${year}/house-of-representatives/${stateConstituencyLink(
      state,
      constituency
    )}`
  }

  static houseOfAssembly(year: string, state = "", constituency = "") {
    return `/elections/${year}/state-houses-of-assembly/${stateConstituencyLink(
      state,
      constituency
    )}`
  }

  static senate(year: string, state = "", constituency = "") {
    return `/elections/${year}/senate/${stateConstituencyLink(
      state,
      constituency
    )}`
  }

  static house(year: string, state = "", constituency = "") {
    return URLFactory.houseOfReps(year, state, constituency)
  }

  static state_house(year: string, state = "", constituency = "") {
    return URLFactory.houseOfAssembly(year, state, constituency)
  }

  static "house-of-representatives"(
    year: string,
    state = "",
    constituency = ""
  ) {
    return URLFactory.houseOfReps(year, state, constituency)
  }

  static "state-houses-of-assembly"(
    year: string,
    state = "",
    constituency = ""
  ) {
    return URLFactory.houseOfAssembly(year, state, constituency)
  }
}

export const calculatePredefinedDates = (selectedOption: string) => {
  const currentDate = new Date()

  const predefinedRanges: Record<
    string,
    {
      operationName: "Between" // Between
      predefinedOperation: string // Last 5 days
      start: Date
      end: Date
    }
  > = {
    "Last 5 days": {
      operationName: "Between",
      predefinedOperation: "Last 5 days",
      start: subDays(currentDate, 5),
      end: currentDate
    },
    "1 month": {
      operationName: "Between",
      predefinedOperation: "1 month",
      start: subMonths(currentDate, 1),
      end: currentDate
    },
    "6 months": {
      operationName: "Between",
      predefinedOperation: "6 months",
      start: subMonths(currentDate, 6),
      end: currentDate
    },
    YTD: {
      operationName: "Between",
      predefinedOperation: "YTD",
      start: startOfYear(currentDate),
      end: currentDate
    }
  }

  return predefinedRanges[selectedOption] || null
}
