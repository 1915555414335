import { useWindowSize } from "../../hooks/useWindowSize"

type HTMLTags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p"

type TypographyOption = [string, HTMLTags]

const TYPOGRAPHY_OPTIONS = {
  p_18: ["text-p_18", "p"],
  p_16: ["text-p_16", "p"],
  p_15: ["text-p_15", "p"],
  p_14: ["text-p_14", "p"],
  p_12: ["text-p_12", "p"],
  p_10: ["text-p_10", "p"],
  btn_18: ["text-btn_18", "p"],
  btn_16: ["text-btn_16", "p"],
  btn_15: ["text-btn_15", "p"],
  btn_14: ["text-btn_14", "p"],
  btn_12: ["text-btn_12", "p"],
  btn_10: ["text-btn_10", "p"],
  h1: ["text-h1", "h1"],
  h2: ["text-h2", "h2"],
  h3: ["text-h3", "h3"],
  h4: ["text-h4", "h4"],
  h5: ["text-h5", "h5"],
  h6: ["text-h6", "h6"]
}

const fontWeightMapper = {
  light: "font-light",
  regular: "font-normal",
  semibold: "font-semibold",
  bold: "font-bold"
}

const FONT_FAMILY_MAPPER = {
  inherit: "",
  jakarta: "font-jakarta",
  halant: "font-halant",
  gambetta: "font-gambetta",
  sans: "font-sans"
}
export type TypographyOptions = keyof typeof TYPOGRAPHY_OPTIONS
type TypographyProps = {
  type: TypographyOptions
  children: string | string[]
  fontWeight?: keyof typeof fontWeightMapper
  mobileType?: TypographyOptions
  portraitOnlyType?: TypographyOptions
  family?: keyof typeof FONT_FAMILY_MAPPER
}

const Typography = (props: TypographyProps) => {
  const [isMobile, width] = useWindowSize()
  const {
    children,
    fontWeight = "regular",
    mobileType,
    portraitOnlyType,
    family = "inherit"
  } = props
  let type = isMobile ? mobileType || props.type : props.type
  type = width > 767 && width <= 1024 ? portraitOnlyType || type : type

  const fontWeightClass = fontWeightMapper[fontWeight]
  const familyClass = FONT_FAMILY_MAPPER[family]
  const [className, Component] = TYPOGRAPHY_OPTIONS[type] as TypographyOption

  return (
    <Component className={`${className} ${fontWeightClass} ${familyClass}`}>
      {children}
    </Component>
  )
}

export default Typography
