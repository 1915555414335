import alarmIcon from "@/public/assets/icons/elections/alarm.svg"
import Link from "@/src/common/components/Link"
import useVisibilityChange from "@/src/common/hooks/useVisibilityChange"
import classNames from "classnames"
import Image from "next/legacy/image"

import { MY_ELECTIONS_COPY } from "../../business-layer/constants"
import { URLFactory } from "../../business-layer/factories/URLFactory"
import Typography from "../Typography"

const SignupForUpdates = ({
  onClickMyElections
}: {
  onClickMyElections?: () => void
}) => {
  const { tabIsVisible } = useVisibilityChange()
  const { description } = MY_ELECTIONS_COPY

  return (
    <div className="bg-[#ECF7ED] md:bg-white p-6">
      <h5 className="text-gray-800 text-base md:text-xl font-medium">
        Get live results
      </h5>
      <p className="text-gray-600 mt-3 text-sm md:text-base">{description}</p>
      <Link legacyBehavior href={URLFactory.myElections}>
        <a
          onClick={onClickMyElections}
          className={`block mt-5 bg-black text-white flex items-start justify-center w-full p-4 ${Typography.className(
            { type: "p15x" }
          )}`}
        >
          <div
            className={classNames("mr-2 inline-block h-3", {
              "animate-wiggle": tabIsVisible
            })}
          >
            <Image src={alarmIcon} alt={"_"} width={16} height={16} />
          </div>{" "}
          Track Elections
        </a>
      </Link>
    </div>
  )
}

export default SignupForUpdates
