import Link from "@/src/common/components/Link"
import useStearsRouter from "@/src/common/hooks/useStearsRouter"

import DefaultFooter from "../../../sb/components/Footer"
import { URLFactory } from "../../business-layer/factories/URLFactory"
import Logo from "../Logo"
import Typography from "../Typography"

const LEGAL_DISCLAIMER_PARARGRAPHS = (
  <section className="">
    <Typography type="p15" className="text-gray-400 mt-5">
      All information on{" "}
      <Link legacyBehavior href={"http://www.stears.co"}>
        <a className="hover:underline">stears.co</a>
      </Link>{" "}
      relating to elections in Nigeria are published in good faith and for
      general information purpose only. We do not not make any warranties about
      the completeness, reliability and accuracy of any information on this
      website, and we are not responsible for any errors, omissions, or any
      results obtained. Any action you take based on the information provided on
      this website, is strictly at your own risk and we will not be liable for
      any loss or damage that may arise as a result of your use of any
      information on this website.
    </Typography>
    <Typography type="p15" className="text-gray-400 mt-5">
      The information on this website is not solely sourced from the Independent
      National Electoral Commission (INEC) as we also rely on information
      provided by secondary sources such as media outlets and news companies.
      These resources are maintained by independent providers and we do not
      monitor these resources nor can we guarantee their accuracy, relevance,
      timeliness or completeness. Furthermore, the inclusion of such resources
      is not an endorsement of any of the views expressed by these independent
      providers.
    </Typography>
    <Typography type="p15" className="text-gray-400 mt-5">
      Where you intend to cite, reuse or reference any information provided on
      this website in any document, website or blog, we expect you to
      acknowledge the source of the information by using the following citation
      &quot;Name of publication, date and year accessed&quot;.
    </Typography>
  </section>
)

export const Footer = () => {
  const { query } = useStearsRouter()
  return (
    <DefaultFooter
      logoOverride={
        <Link legacyBehavior href={URLFactory.governor(query.year as string)}>
          <a>
            <Logo color="white" size="sm" className="md:hidden" />
            <Logo color="white" size="md" className="mt-1 -md:hidden" />
          </a>
        </Link>
      }
      additionalSections={
        <section className="border-t border-gray-600 w-full">
          <article className="max-w-screen-2xl mx-auto py-10 px-6 md:px-10">
            <Typography type="p15" weight="medium">
              Legal Disclaimer
            </Typography>
            {LEGAL_DISCLAIMER_PARARGRAPHS}
          </article>
        </section>
      }
    />
  )
}

export default Footer
