import GubernatorialLiveBlogCard from "@/src/elections/components/GubernatorialLiveBlogCard"
import AsideDownloadMobile from "@/src/sb/components/DownloadMobileNudge/AsideDownloadMobile"
import classNames from "classnames"
import { ReactNode } from "react"

import { ElectionArticleInfo } from "../../business-layer/types"
import ArticleFeed from "../ArticleFeed"
import ExplorePastResults from "../ExplorePastResults"
import SignupForUpdates from "../SignupForUpdates"
import Card from "./Card"

const Page = ({ children }: { children?: ReactNode }) => (
  <div className="block bg-gray-100 md:px-24 md:py-16">{children}</div>
)

Page.TwoColumns = ({
  children,
  showSignupCTA = true,
  showExplorePastResults = false,
  articles,
  onClickMyElections,
  onClickArticle,
  showMobileAppDownloadCard,
  onClickGubernatorialLiveBlog,
  showGubernatorialLiveBlog
}: {
  articles: Array<ElectionArticleInfo>
  children?: ReactNode
  showSignupCTA?: boolean
  showExplorePastResults?: boolean
  onClickMyElections?: () => void
  onClickGubernatorialLiveBlog?: () => void
  onClickArticle?: (article: ElectionArticleInfo) => void
  showMobileAppDownloadCard?: boolean
  showGubernatorialLiveBlog?: boolean
}) => {
  return (
    <div className="bg-gray-100 w-full flex flex-col xl:py-16 xl:pb-20 xl:flex-row xl:justify-between">
      <section className="w-full xl:max-w-[820px] relative">{children}</section>

      <section className="xl:ml-18 xl:min-w-[375px] flex flex-col">
        {showGubernatorialLiveBlog ? (
          <div className="my-6 md:p-0 md:mt-6 xl:mt-0 bg-white md:bg-transparent max-w-[824px] mx-auto w-full order-1 md:order-1">
            <GubernatorialLiveBlogCard
              onClickGubernatorialLiveBlog={onClickGubernatorialLiveBlog}
            />
          </div>
        ) : null}

        {showSignupCTA ? (
          <div className="p-6 md:p-0 md:mt-6 xl:mt-0 bg-white md:bg-transparent max-w-[824px] mx-auto w-full order-1 md:order-1">
            <SignupForUpdates onClickMyElections={onClickMyElections} />
          </div>
        ) : null}

        {articles.length === 0 ? null : (
          <Card.Container
            className={classNames("md:mt-10 w-full order-2 md:order-2", {
              "xl:mt-0": !showSignupCTA
            })}
          >
            <ArticleFeed articles={articles} onClickAction={onClickArticle} />
          </Card.Container>
        )}

        {showMobileAppDownloadCard && (
          <div className="p-6 md:p-0 bg-white md:bg-transparent max-w-[824px] mx-auto w-full order-1 md:order-3 xl:mb-6">
            <AsideDownloadMobile />
          </div>
        )}

        {showExplorePastResults ? (
          <Card.Container
            className={classNames(
              {
                "lg:mt-10": showSignupCTA,
                "md:mt-10 xl:mt-0": !showSignupCTA
              },
              "w-full order-3 md:order-4"
            )}
          >
            <ExplorePastResults />
          </Card.Container>
        ) : null}
      </section>
    </div>
  )
}

export default Page
